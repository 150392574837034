.logomark-child-desktop {
    width: 2.94rem;
    position: center;
    border-radius: 5.02px;
    height: 2.94rem;
    overflow: hidden;
    flex-shrink: 0;
}
.logomark-desktop {
    position: absolute;
    top: calc(50% - 15.5px);
    left: calc(50% - 70.5px);
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.coffee-desktop {
    margin: 0;
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: xx-large;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: inherit;
    color: #5645A1;
    /* font-family: "SF Pro"; */
    /* font-size: 24.75px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.logotype-desktop {
    position: absolute;
    height: 100%;
    width: 70.42%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 29.58%;
}
.logo-wrap-desktop {
    width: 8.88rem;
    position: relative;
    height: 2rem;
}
.logo-desktop {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.text-desktop {
    position: relative;
    line-height: 1.5rem;
    font-weight: 600;
}
.button-desktop {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.dropdown-header-navigation-tri-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.25rem 0rem;
}
.navigation-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    font-size: 1rem;
    color: #584741;
}
.content-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2.5rem;
}
.button2-desktop {
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #cc785c;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.63rem 1.13rem;
}
.navigation-actions-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    color: #fff;
}
.container-desktop {
    width: 76.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.container-wrapper-desktop {
    background-color: #fff;
    height: 5rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.13rem 6.81rem;
    box-sizing: border-box;
    font-size: 1.5rem;
    color: #cc785c;
    width:100%
}
.coffee-an-ios-desktop {
    margin: 0;
    width: 39.25rem;
    position: relative;
    font-size: inherit;
    font-weight: 700;
    font-family: inherit;
    display: inline-block;
    flex-shrink: 0;
    z-index: 0;
}
.because-less-is-desktop {
    margin: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    width: 28.38rem;
}
.group-child-desktop {
    position: absolute;
    top: 3.5rem;
    left: 13.13rem;
    width: 11.89rem;
    height: 0.54rem;
}
.group-item-desktop {
    position: absolute;
    top: 4.24rem;
    left: 15.48rem;
    width: 8.67rem;
    height: 0.38rem;
}
.because-less-is-more-parent-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 28.38rem;
    height: 4.63rem;
}
.o-desktop {
    width: 28.38rem;
    position: absolute;
    margin: 0 !important;
    top: 16.69rem;
    left: 0rem;
    height: 4.63rem;
    z-index: 1;
    font-size: 2.5rem;
    color: #734d40;
}
.coffee-an-ios-exclusive-datin-parent-desktop {
    height: 22.38rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 0.63rem;
}
.content1-desktop {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.input-desktop {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 0.88rem;
}
.input-with-label-desktop {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.hint-text-desktop {
    align-self: stretch;
    position: relative;
    font-size: 0.88rem;
    line-height: 1.25rem;
    color: #584741;
}
.input-field-desktop {
    width: 22.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.38rem;
}
.placeholder-desktop {
    width: 1.25rem;
    position: relative;
    height: 1.25rem;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}
.button3-desktop {
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    gap: 0.5rem;
    color: #fff;
}
.email-capture-desktop {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    font-size: 1rem;
    color: #a48d85;
}
.frame-group-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2.88rem;
}
.group-inner-desktop {
    position: absolute;
    top: 0.75rem;
    left: 4.31rem;
    background-color: #fbd5c8;
    filter: blur(253.08px);
    width: 30.63rem;
    height: 31.88rem;
}
.rectangle-icon-desktop {
    position: absolute;
    top: 4.91rem;
    left: 13.68rem;
    width: 21.66rem;
    height: 29.74rem;
    object-fit: contain;
}
.rectangle-icon1-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 18.33rem;
    height: 29.72rem;
    object-fit: contain;
}
.group-wrapper-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 35.34rem;
    height: 34.65rem;
}
.frame-wrapper-desktop {
    width: 35.31rem;
    position: relative;
    height: 34.63rem;
}
.frame-parent-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.19rem;
}
.div-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3.75rem 6.63rem;
}
.indian-dating-apps-desktop {
    margin: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    width: 42.88rem;
}
.indian-dating-apps-are-clutter-wrapper-desktop {
    width: 42.88rem;
    position: relative;
    height: 13.69rem;
}
.discover-100-verified-desktop {
    margin: 0;
    position: absolute;
    top: 4.25rem;
    left: 3.63rem;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    width: 23.94rem;
}
.discover-100-verified-profile-wrapper-desktop {
    position: absolute;
    top: 3.88rem;
    left: 0rem;
    border-radius: 28.71px;
    background-color: #cc785c;
    border: 14.4px solid #fde1ce;
    box-sizing: border-box;
    width: 29.25rem;
    height: 20.5rem;
    overflow: hidden;
}
.frame-icon-desktop {
    position: absolute;
    top: 0rem;
    left: 41.19rem;
    width: 6.94rem;
    height: 10.94rem;
}
.group-div-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 32.13rem;
    height: 24.38rem;
}
.group-container-desktop {
    width: 32.13rem;
    position: relative;
    height: 24.38rem;
    font-size: 2.5rem;
    color: #fff;
}
.frame-container-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.75rem;
}
.div1-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.5rem 6.63rem;
}
.access-your-analytics-desktop {
    margin: 0;
    width: 39.44rem;
    position: relative;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    flex-shrink: 0;
}
.rectangle-div-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: #ffcdbc;
    filter: blur(196.13px);
    border: 47.1px solid #fff0f0;
    box-sizing: border-box;
    width: 36.81rem;
    height: 25.38rem;
}
.avg-time-spent-desktop {
    position: absolute;
    top: 4.78rem;
    left: 1.26rem;
    font-weight: 500;
    display: inline-block;
    width: 11.57rem;
}
.mins-desktop {
    position: absolute;
    top: 1.51rem;
    left: 1.26rem;
    font-size: 2.35rem;
    font-weight: 500;
    color: #29261b;
    display: inline-block;
    width: 9.55rem;
}
.solarclock-square-bold-duoton-icon-desktop {
    position: absolute;
    top: 0.34rem;
    left: 11.65rem;
    width: 2.51rem;
    height: 2.51rem;
    overflow: hidden;
}
.avg-time-spent-on-your-profil-parent-desktop {
    position: absolute;
    top: 1.59rem;
    left: 1.76rem;
    border-radius: 13.41px;
    background-color: #fff;
    border: 2.7px solid #e1d0d0;
    box-sizing: border-box;
    width: 14.5rem;
    height: 8.38rem;
    overflow: hidden;
    font-size: 1.09rem;
    color: #584741;
}
.times-desktop {
    position: absolute;
    top: 1.51rem;
    left: 1.26rem;
    font-weight: 500;
    display: inline-block;
    width: 9.55rem;
}
.no-of-times-desktop {
    position: absolute;
    top: 4.78rem;
    left: 1.26rem;
    font-size: 1.09rem;
    font-weight: 500;
    color: #584741;
    display: inline-block;
    width: 11.73rem;
}
.times-parent-desktop {
    position: absolute;
    top: 1.59rem;
    left: 17.52rem;
    border-radius: 13.41px;
    background-color: #fff;
    border: 2.7px solid #e1d0d0;
    box-sizing: border-box;
    width: 14.5rem;
    height: 8.38rem;
    overflow: hidden;
}
.impression-factor-of-desktop {
    margin: 0;
}
.impression-factor-of-container-desktop {
    position: absolute;
    top: 4.78rem;
    left: 1.26rem;
    font-size: 1.09rem;
    font-weight: 500;
    color: #584741;
    display: inline-block;
    width: 11.57rem;
    height: 2.93rem;
}
.parent-desktop {
    position: absolute;
    top: 11.31rem;
    left: 1.76rem;
    border-radius: 13.41px;
    background-color: #fff;
    border: 2.7px solid #e1d0d0;
    box-sizing: border-box;
    width: 14.5rem;
    height: 8.38rem;
    overflow: hidden;
}
.frame-child-desktop {
    position: absolute;
    top: 2.1rem;
    left: 1.34rem;
    border-radius: 40.23px;
    background: linear-gradient(90deg, #009806 99.99%, #ff3333);
    width: 9.55rem;
    height: 1.93rem;
}
.div4-desktop {
    position: absolute;
    height: 19%;
    width: 13.87%;
    top: 4%;
    left: 9.25%;
    font-weight: 500;
    display: inline-block;
}
.div5-desktop {
    position: absolute;
    height: 17%;
    width: 20.23%;
    top: 28%;
    left: 13.87%;
    font-weight: 500;
    display: inline-block;
}
.div6-desktop {
    position: absolute;
    height: 17%;
    width: 20.23%;
    top: 28%;
    left: 52.6%;
    font-weight: 500;
    display: inline-block;
}
.div7-desktop {
    position: absolute;
    height: 19%;
    width: 13.87%;
    top: 44%;
    left: 80.35%;
    font-weight: 500;
    display: inline-block;
}
.div8-desktop {
    position: absolute;
    height: 13%;
    width: 9.25%;
    top: 11%;
    left: 37.57%;
    font-size: 1.01rem;
    font-weight: 500;
    color: #29261b;
    display: inline-block;
}
.solarflag-bold-duotone-icon-desktop {
    position: absolute;
    height: 12%;
    width: 6.94%;
    top: 11%;
    right: 27.17%;
    bottom: 77%;
    left: 65.9%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.solarflag-bold-duotone-icon1-desktop {
    position: absolute;
    height: 12%;
    width: 6.94%;
    top: 11%;
    right: 81.5%;
    bottom: 77%;
    left: 11.56%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
}
.solargraph-new-bold-duotone-parent-desktop {
    position: absolute;
    top: 11.31rem;
    left: 17.52rem;
    border-radius: 13.41px;
    background-color: #fff;
    border: 2.7px solid #e1d0d0;
    box-sizing: border-box;
    width: 14.5rem;
    height: 8.38rem;
    overflow: hidden;
    font-size: 1.17rem;
    color: #fff;
}
.frame-div-desktop {
    position: absolute;
    top: 2.16rem;
    left: 1.56rem;
    border-radius: 21.45px;
    background-color: #ffe3da;
    border: 1.3px solid #fda689;
    box-sizing: border-box;
    width: 33.78rem;
    height: 21.29rem;
    overflow: hidden;
}
.rectangle-group-desktop {
    width: 36.81rem;
    position: relative;
    height: 25.38rem;
    font-size: 2.35rem;
    font-family: 'SF Pro';
}
.access-your-analytics-to-learn-parent-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}
.div2-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.75rem 6.63rem;
}
.iphone-15-blue-desktop {
    position: absolute;
    top: calc(50% - 225px);
    left: calc(50% - 206.5px);
    width: 13.66rem;
    height: 28.13rem;
    object-fit: cover;
}
.save-flag-desktop {
    position: absolute;
    top: 0.75rem;
    left: 5.19rem;
    font-weight: 600;
}
.save-flag-wrapper-desktop {
    position: absolute;
    top: 7.44rem;
    left: 0.56rem;
    border-radius: 41px;
    background-color: #cc785c;
    border: 1px solid #29261b;
    box-sizing: border-box;
    width: 15.13rem;
    height: 2.75rem;
    overflow: hidden;
    font-size: 1rem;
}
.green-flag-desktop {
    position: absolute;
    top: 0.88rem;
    left: 2.25rem;
    line-height: 1rem;
    font-weight: 500;
}
.solarflag-bold-duotone-icon2-desktop {
    position: absolute;
    top: 0.63rem;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
}
.green-flag-parent-desktop {
    position: absolute;
    top: 0.56rem;
    left: 0.56rem;
    border-radius: 36px;
    background-color: #5645a1;
    width: 7.38rem;
    height: 2.75rem;
    overflow: hidden;
}
.red-flag-parent-desktop {
    position: absolute;
    top: 0.56rem;
    left: 8.31rem;
    border-radius: 40px;
    border: 1px solid #584741;
    box-sizing: border-box;
    width: 7.38rem;
    height: 2.75rem;
    overflow: hidden;
    color: #29261b;
}
.this-rating-is-desktop {
    position: absolute;
    top: 4.5rem;
    left: 0.81rem;
    font-size: 0.75rem;
    color: #584741;
    display: inline-block;
    width: 14.44rem;
    height: 1.75rem;
}
.frame-parent1-desktop {
    position: absolute;
    top: 10.12rem;
    left: 8.56rem;
    border-radius: 30px;
    /* background-color: #fff; */
    /* box-shadow: 0px 4px 4px rgba(106, 112, 121, 0.18), 0px 0px 67px rgba(255, 157, 125, 0.4); */
    /* border: 1px solid #f5f5f4; */
    box-sizing: border-box;
    width: 16.25rem;
    height: 10.75rem;
    /* overflow: hidden; */
}
.zoom-in-desktop{
    top: 0;
    width: 16.25rem;
    height: 10.75rem;
    left:-25rem;
}
.iphone-15-blue-parent-desktop {
    width: 25.81rem;
    position: relative;
    height: 28.13rem;
}
.red-desktop {
    color: #ff3333;
}
.green-desktop {
    color: #009806;
}
.go-beyond-a-container-desktop {
    margin: 0;
    width: 47rem;
    position: relative;
    font-size: 3.75rem;
    font-weight: 500;
    font-family: Inter;
    display: inline-block;
    flex-shrink: 0;
    color: #29261b;
}
.group-parent-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3.94rem;
}
.div9-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.75rem 6.63rem;
    font-size: 0.88rem;
    color: #fff;
    font-family: 'SF Pro';
}
.heading-desktop {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 2.38rem;
    font-weight: 600;
    font-family: inherit;
}
.supporting-text-desktop {
    align-self: stretch;
    position: relative;
    font-size: 1.25rem;
    line-height: 1.88rem;
    color: #584741;
}
.heading-and-supporting-text-desktop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}
.input1-desktop {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.63rem 0.88rem;
}
.text6-desktop {
    width: 3.81rem;
    position: relative;
    line-height: 1.5rem;
    font-weight: 600;
    display: inline-block;
    flex-shrink: 0;
}
.button4-desktop {
    width: 7.81rem;
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #cc785c;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.63rem 1.13rem;
    gap: 0.5rem;
    color: #fff;
}
.email-capture1-desktop {
    width: 25rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    font-size: 1rem;
    color: #a48d85;
}
.content2-desktop {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}
.container1-desktop {
    position: absolute;
    top: 3rem;
    left: 6.63rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.section-desktop {
    position: absolute;
    top: 0rem;
    left: 0rem;
    background-color: #fff4f1;
    width: 90.38rem;
    height: 11.25rem;
    color: #29261b;
    width: 100%;
}
.heading1-desktop {
    margin: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: inherit;
    line-height: 2.38rem;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    width: 49rem;
}
.logomark1-desktop {
    position: absolute;
    height: 96.88%;
    width: 50.83%;
    top: 1.56%;
    right: 20.82%;
    bottom:50%;
    left: 0.35%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logomark1-child {
    width: 3.25rem; /* Adjust the size as needed */
    height: auto; /* Keep the aspect ratio of the image */
    margin-right: 0.05rem; /* Add space between the image and the text */
}

.supporting-text1-desktop {
    align-self: stretch;
    position: relative;
    font-size: 1rem;
    line-height: 1.5rem;
}
.logo-and-supporting-text-desktop {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}
.logo-and-links-desktop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.heading2-desktop {
    width: 8.31rem;
    position: relative;
    line-height: 1.5rem;
    font-weight: 500;
    display: inline-block;
}
.mobile-app-store-badge-desktop {
    align-self: stretch;
    position: relative;
    border-radius: 7px;
    max-width: 100%;
    overflow: hidden;
    height: 2.5rem;
    flex-shrink: 0;
    mix-blend-mode: normal;
}
.actions-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.app-store-actions-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    font-size: 1rem;
}
.content4-desktop {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}
.container2-desktop {
    position: absolute;
    top: 14.38rem;
    left: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 2rem;
    box-sizing: border-box;
    font-size: 1.5rem;
}
.footer-text-desktop {
    flex: 1;
    position: relative;
    line-height: 1.5rem;
}
.terms-privacy-links {
    font-size: medium;
}
.social-icon-desktop {
    width: 1.5rem;
    position: relative;
    height: 1.5rem;
    overflow: hidden;
    flex-shrink: 0;
}
.social-icons-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
}
.content5-desktop {
    width: 95%;
    border-top: 1px solid #f5a88e;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 0rem 0rem;
    gap: 2rem;
}
.container3-desktop {
    position: absolute;
    top: 22.5rem;
    left: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 2rem;
    font-size: 1rem;
    width:90%
}
.footer-desktop {
    width: 90rem;
    position: relative;
    background-color: #cc785c;
    height: 29rem;
    overflow: hidden;
    flex-shrink: 0;
    font-size: 1.88rem;
    color: #fff;
    width: 100%;
}
.coffee-landing-page-desktop {
    width: 100%;
    position: relative;
    background-color: #fff4f1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 3.75rem;
    color: #29261b;
    font-family: Inter;
}

