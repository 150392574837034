/* Base Styles for Both Desktop and Mobile */
.contact-container-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    background-color: #fff4f1;
    font-family: 'SF Pro', sans-serif;
    box-shadow: 0px 4px 4px rgba(106, 112, 121, 0.18), 0px 0px 67px rgba(255, 157, 125, 0.4);
    border-radius: 10px;
    max-width: 100%;
    margin: auto;
  }
  
  .heading-desktop {
    margin: 0;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: #cc785c;
  }
  
  .form-desktop {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }
  
  .input-with-label-desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .label-desktop {
    font-size: 1.25rem;
    color: #29261b;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .input-desktop, .textarea-desktop {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e1d0d0;
    border-radius: 8px;
    background-color: #fff;
    font-size: 1rem;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  
  .textarea-desktop {
    height: 120px;
    resize: none;
  }
  
  .buttons-wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  
  .button3-desktop, .button-back-desktop {
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 0.75rem 1.13rem;
    font-size: 1.25rem;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .button-back-desktop {
    background-color: #5645A1; /* Different color for the Back button */
  }
  
  .contact-info-desktop {
    margin-top: 2rem;
    background-color: #ffe3da;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;
  }
  
  .contact-info-desktop h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #cc785c;
  }
  
  .contact-info-desktop p {
    font-size: 1.25rem;
    color: #29261b;
  }
  
  /* Responsive Styles */
  @media (min-width: 768px) {
    .contact-container-desktop {
      max-width: 600px;
      padding: 3rem;
    }
  
    .heading-desktop {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  
    .button3-desktop, .button-back-desktop {
      width: auto;
      padding: 0.75rem 2rem;
      font-size: 1.25rem;
    }
  
    .buttons-wrapper {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  
  @media (max-width: 768px) {
    .contact-container-desktop {
      padding: 1rem;
    }
  
    .heading-desktop {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  
    .label-desktop {
      font-size: 1rem;
    }
  
    .input-desktop, .textarea-desktop {
      padding: 0.5rem;
      font-size: 1rem;
    }
  
    .button3-desktop, .button-back-desktop {
      width: 100%; /* Full width buttons for mobile */
      padding: 0.5rem;
      font-size: 1rem;
    }
  
    .buttons-wrapper {
      flex-direction: column;
      gap: 0.75rem;
    }
  
    .contact-info-desktop {
      padding: 0.75rem;
    }
  
    .contact-info-desktop p {
      font-size: 1rem;
    }
  }
  