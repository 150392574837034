/* PrivacyPolicy.css */
.privacy-policy-container {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  h2 {
    font-size: 28px; /* Adjust font size for better readability */
    margin-bottom: 16px;
    color: #333;
  }
  
  h3 {
    font-size: 22px; /* Adjust font size for consistency */
    margin-top: 20px;
    margin-bottom: 12px;
    color: #444;
  }
  
  p {
    font-size: 16px; /* Adjust font size for readability */
    margin-bottom: 12px;
    color: #555;
  }
  
  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  ul li {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  