.child {
    width: 19.05rem;
    position: absolute;
    margin: 0 !important;
    top: 15.06rem;
    left: 1.75rem;
    background-color: #fbd5c8;
    filter: blur(113.45px);
    height: 15.88rem;
    z-index: 0;
}
.logomark-child {
    width: 1.94rem;
    position: relative;
    border-radius: 7.02px;
    height: 1.94rem;
    overflow: hidden;
    flex-shrink: 0;
}
.logomark {
    position: absolute;
    top: calc(50% - 15.5px);
    left: calc(50% - 70.5px);
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.coffee {
    margin: 0;
    position: absolute;
    top: 12.5%;
    left: 3%;
    font-size: inherit;
    line-height: 1.5rem;
    font-weight: 600;
    font-family: inherit;
    color: #5645A1;
}
.logotype {
    position: absolute;
    height: 100%;
    width: 70.42%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 29.58%;
}
.logo-wrap {
    width: 8.88rem;
    position: relative;
    height: 2rem;
}
.text {
    position: relative;
    line-height: 1.5rem;
    font-weight: 600;
}
.button {
    width: 6rem;
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #cc785c;
    box-sizing: border-box;
    height: 1.88rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.63rem 1.13rem;
    font-size: 1rem;
    color: #fff;
}
.logo-wrap-parent {
    width: 22.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5.19rem;
}
.header {
    align-self: stretch;
    flex: 1;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem;
    z-index: 1;
}
.coffee-an-ios {
    margin: 0;
    width: 20.5rem;
    position: relative;
    font-size: inherit;
    letter-spacing: -2px;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    height: 8.25rem;
    flex-shrink: 0;
}
.rectangle-icon {
    width: 15.4rem;
    position: absolute;
    margin: 0 !important;
    top: -1.81rem;
    left: -0.37rem;
    height: 19.21rem;
    object-fit: contain;
    z-index: 1;
}
.rectangle-icon1 {
    width: 16.27rem;
    position: absolute;
    margin: 0 !important;
    top: 0rem;
    left: 6.31rem;
    height: 19.43rem;
    object-fit: contain;
    z-index: 1;
}
.rectangle-shadow {
    width: 30.4rem;
    position: absolute;
    margin: 0 !important;
    top: -1.81rem;
    left: -0.37rem;
    height: 19.21rem;
    object-fit: contain;
    z-index: -1;
}
.rectangle-parent {
    width: 20.5rem;
    /* box-shadow: 0px 3.0441558361053467px 3.04px rgba(0, 0, 0, 0.25); */
    height: 17.35rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.48rem;
    box-sizing: border-box;
    position: relative;
    gap: 0.48rem;
}

.because-less-is {
    margin: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: inherit;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    width: 20.5rem;
    height: 1.81rem;
}
.group-child {
    position: absolute;
    top: 2.06rem;
    left: 9.69rem;
    width: 7.61rem;
    height: 0.34rem;
}
.group-item {
    position: absolute;
    top: 2.53rem;
    left: 11.19rem;
    width: 5.55rem;
    height: 0.24rem;
}
.because-less-is-more-parent {
    width: 20.5rem;
    position: relative;
    height: 2.78rem;
}
.frame-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.13rem;
    font-size: 1.56rem;
    color: #734d40;
}
.coffee-an-ios-exclusive-datin-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 1rem;
    z-index: 2;
    text-align: center;
    font-size: 2.25rem;
    color: #29261b;
}
.div {
    align-self: stretch;
    background-color: #fff4f1;
    height: 36.5rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 0rem 1.5rem;
    box-sizing: border-box;
    position: relative;
    gap: 1.5rem;
    font-size: 1.5rem;
    color: #cc785c;
}
.label {
    width: 2.25rem;
    position: relative;
    line-height: 1.25rem;
    font-weight: 500;
    display: none;
}
.text1 {
    flex: 1;
    position: relative;
    line-height: 1.5rem;
}
.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.input {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 0.88rem;
    font-size: 1rem;
    color: #a48d85;
}
.input-with-label {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.38rem;
}
.hint-text {
    align-self: stretch;
    position: relative;
    line-height: 1.25rem;
    color: #584741;
}
.input-field {
    width: 20.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.38rem;
}
.placeholder {
    width: 1.25rem;
    position: relative;
    height: 1.25rem;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}
.button1 {
    width: 20.5rem;
    border-radius: 8px;
    background-color: #cc785c;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    gap: 0.5rem;
    font-size: 1rem;
    color: #fff;
}
.email-capture1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}
.container1 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 10rem;
}
.email-capture {
    align-self: stretch;
    flex: 1;
    background-color: #fff4f1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem 0rem;
    font-size: 0.88rem;
    color: #344054;
}
.indian-dating-apps {
    margin: 0;
    width: 20.5rem;
    position: relative;
    font-size: inherit;
    letter-spacing: -2px;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    height: 11.38rem;
    flex-shrink: 0;
}
.discover-100-verified {
    margin: 0;
    position: absolute;
    top: 2.06rem;
    left: 2.44rem;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    width: 14.06rem;
    height: 7.25rem;
}
.discover-100-verified-profile-wrapper {
    position: absolute;
    top: 2rem;
    left: 0rem;
    border-radius: 20px;
    background-color: #cc785c;
    border: 8.4px solid #fde1ce;
    box-sizing: border-box;
    width: 18rem;
    height: 11.38rem;
    overflow: hidden;
}
.group-inner {
    position: absolute;
    top: 0rem;
    left: 14rem;
    width: 5.25rem;
    height: 5.25rem;
}
.frame-group {
    position: absolute;
    top: 0rem;
    left: 1.25rem;
    width: 19.25rem;
    height: 13.38rem;
}
.d {
    width: 20.5rem;
    position: relative;
    height: 13.38rem;
    text-align: left;
    font-size: 1.5rem;
    color: #fff;
}
.indian-dating-apps-are-clutter-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.38rem;
}
.container2 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 1rem;
}
.div1 {
    align-self: stretch;
    background-color: #fff4f1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0rem;
    text-align: center;
}
.access-your-analytics {
    margin: 0;
    width: 20.5rem;
    position: relative;
    font-size: inherit;
    letter-spacing: -2px;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    height: 11.56rem;
    flex-shrink: 0;
}
.avg-time-spent {
    position: absolute;
    top: 3.06rem;
    left: 0.81rem;
    font-weight: 500;
    display: inline-block;
    width: 7.42rem;
}
.mins {
    position: absolute;
    top: 0.97rem;
    left: 0.81rem;
    font-size: 1.51rem;
    font-weight: 500;
    color: #29261b;
    display: inline-block;
    width: 6.13rem;
}
.solarclock-square-bold-duoton-icon {
    position: absolute;
    top: 0.22rem;
    left: 7.47rem;
    width: 1.61rem;
    height: 1.61rem;
    overflow: hidden;
}
.avg-time-spent-on-your-profil-parent {
    width: 9.3rem;
    position: relative;
    border-radius: 8.6px;
    background-color: #fff;
    border: 1.7px solid #e1d0d0;
    box-sizing: border-box;
    height: 5.38rem;
    overflow: hidden;
    flex-shrink: 0;
}
.times {
    position: absolute;
    top: 0.97rem;
    left: 0.81rem;
    font-weight: 500;
    display: inline-block;
    width: 6.13rem;
}
.no-of-times {
    position: absolute;
    top: 3.06rem;
    left: 0.81rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: #584741;
    display: inline-block;
    width: 7.53rem;
}
.times-parent {
    width: 9.3rem;
    position: relative;
    border-radius: 8.6px;
    background-color: #fff;
    border: 1.7px solid #e1d0d0;
    box-sizing: border-box;
    height: 5.38rem;
    overflow: hidden;
    flex-shrink: 0;
    font-size: 1.51rem;
    color: #29261b;
}
.frame-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
}
.impression-factor-of {
    margin: 0;
}
.impression-factor-of-container {
    position: absolute;
    top: 3.06rem;
    left: 0.81rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: #584741;
    display: inline-block;
    width: 7.42rem;
    height: 1.88rem;
}
.frame-child {
    position: absolute;
    top: 1.34rem;
    left: 0.86rem;
    border-radius: 25.8px;
    background: linear-gradient(90deg, #009806 99.99%, #ff3333);
    width: 6.13rem;
    height: 1.24rem;
}
.div4 {
    position: absolute;
    height: 19%;
    width: 13.87%;
    top: 4%;
    left: 9.25%;
    font-weight: 500;
    display: inline-block;
}
.div5 {
    position: absolute;
    height: 17%;
    width: 20.23%;
    top: 28%;
    left: 13.87%;
    font-weight: 500;
    display: inline-block;
}
.div6 {
    position: absolute;
    height: 17%;
    width: 20.23%;
    top: 28%;
    left: 52.6%;
    font-weight: 500;
    display: inline-block;
}
.div7 {
    position: absolute;
    height: 19%;
    width: 13.87%;
    top: 44%;
    left: 80.35%;
    font-weight: 500;
    display: inline-block;
}
.div8 {
    position: absolute;
    height: 13%;
    width: 9.25%;
    top: 11%;
    left: 37.57%;
    font-size: 0.65rem;
    font-weight: 500;
    color: #29261b;
    display: inline-block;
}
.solarflag-bold-duotone-icon {
    position: absolute;
    height: 12%;
    width: 6.94%;
    top: 11%;
    right: 27.17%;
    bottom: 77%;
    left: 65.9%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.solarflag-bold-duotone-icon1 {
    position: absolute;
    height: 12%;
    width: 6.94%;
    top: 11%;
    right: 81.5%;
    bottom: 77%;
    left: 11.56%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: contain;
}
.solargraph-new-bold-duotone-parent {
    width: 9.3rem;
    position: relative;
    border-radius: 8.6px;
    background-color: #fff;
    border: 1.7px solid #e1d0d0;
    box-sizing: border-box;
    height: 5.38rem;
    overflow: hidden;
    flex-shrink: 0;
    font-size: 0.75rem;
    color: #fff;
}
.frame-parent1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    font-size: 1.51rem;
    color: #29261b;
}
.frame-container {
    border-radius: 14px;
    /* background-color: #ffe3da;
    border: 1.4px solid #fda689; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.75rem 0.63rem;
    gap: 0.56rem;
    text-align: left;
    font-size: 0.7rem;
    color: #584741;
    font-family: 'SF Pro';
}
.container3 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 1rem;
    gap: 2rem;
}
.red {
    color: #ff3333;
}
.span {
    color: #29261b;
}
.green {
    color: #009806;
}
.go-beyond-a-container {
    margin: 0;
    width: 20.5rem;
    position: relative;
    font-size: inherit;
    letter-spacing: -2px;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    height: 11rem;
    flex-shrink: 0;
}
.demo-icon {
    width: 10.85rem;
    position: relative;
    height: 22.33rem;
    object-fit: cover;
    z-index: 0;
}
.save-flag {
    position: absolute;
    top: 0.6rem;
    left: 4.12rem;
    font-weight: 600;
}
.save-flag-wrapper {
    position: absolute;
    top: 5.91rem;
    left: 0.45rem;
    border-radius: 32.55px;
    background-color: #cc785c;
    border: 0.8px solid #29261b;
    box-sizing: border-box;
    width: 12.01rem;
    height: 2.18rem;
    overflow: hidden;
    font-size: 0.79rem;
}
.green-flag {
    position: absolute;
    top: 0.69rem;
    left: 1.79rem;
    line-height: 0.79rem;
    font-weight: 500;
}
.solarflag-bold-duotone-icon2 {
    position: absolute;
    top: 0.5rem;
    left: 0.4rem;
    width: 1.19rem;
    height: 1.19rem;
    overflow: hidden;
}
.green-flag-parent {
    position: absolute;
    top: 0.45rem;
    left: 0.45rem;
    border-radius: 28.58px;
    background-color: #5645a1;
    width: 5.86rem;
    height: 2.18rem;
    overflow: hidden;
}
.red-flag-parent {
    position: absolute;
    top: 0.45rem;
    left: 6.6rem;
    border-radius: 31.76px;
    border: 0.8px solid #584741;
    box-sizing: border-box;
    width: 5.86rem;
    height: 2.18rem;
    overflow: hidden;
    color: #29261b;
}
.this-rating-is {
    position: absolute;
    top: 3.57rem;
    left: 0.65rem;
    font-size: 0.6rem;
    color: #584741;
    display: inline-block;
    width: 11.46rem;
    height: 1.39rem;
}
.frame-parent2 {
    width: 12.91rem;
    position: absolute;
    margin: 0 !important;
    top: 5.76rem;
    left: 7.59rem;
    border-radius: 23.82px;
    background-color: #fff;
    box-shadow: 0px 3.1758341789245605px 3.18px rgba(106, 112, 121, 0.18), 0px 0px 53.2px rgba(255, 157, 125, 0.4);
    border: 0.8px solid #f5f5f4;
    box-sizing: border-box;
    height: 8.54rem;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.demo-parent {
    position: absolute;
    width: 52.92%;
    top: calc(50% - 178.64px);
    right: 47.08%;
    left: 0%;
    height: 22.33rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.63rem;
}
.demo {
    align-self: stretch;
    flex: 1;
    position: relative;
    text-align: left;
    font-size: 0.7rem;
    color: #fff;
    font-family: 'SF Pro';
}
.container4 {
    height: 35.33rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 1rem;
    box-sizing: border-box;
    gap: 2rem;
}
.heading {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 2.38rem;
    font-weight: 600;
    font-family: inherit;
}
.supporting-text {
    align-self: stretch;
    position: relative;
    font-size: 0.88rem;
    color: #584741;
}
.heading-and-supporting-text {
    width: 20.5rem;
    height: 7.88rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.31rem;
}
.email-capture3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    font-size: 0.88rem;
    color: #344054;
}
.container5 {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 10rem;
    gap: 0.69rem;
}
.email-capture2 {
    align-self: stretch;
    background-color: #fff4f1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0rem;
    font-size: 1.88rem;
}
.heading1 {
    margin: 0;
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: inherit;
    line-height: 2.38rem;
    font-weight: 600;
    font-family: inherit;
    display: inline-block;
    width: 49rem;
    color: #5645A1;

}
.logomark1 {
    position: absolute;
    height: 96.88%;
    width: 21.83%;
    top: 1.56%;
    right: 77.82%;
    bottom: 1.56%;
    left: 0.35%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.supporting-text1 {
    width: 11.06rem;
    position: relative;
    font-size: 0.88rem;
    display: inline-block;
}
.logo-parent {
    position: absolute;
    top: 0rem;
    left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.19rem;
}
.mobile-app-store-badge {
    width: 7.8rem;
    position: relative;
    border-radius: 6.47px;
    height: 2.31rem;
    mix-blend-mode: normal;
}
.actions {
    position: relative;
    top: 0rem;
    left: 12.69rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 1rem;
}
.coming-soon {
    font-size: 0.56rem; /* Match the footer text size */
    color: #fff; /* Match the footer text color */
    margin-top: 0.5rem; /* Add space between the badge and the text */
    width: 37%;
    text-align: center; /* Center the text */
}

.frame-parent4 {
    width: 20.5rem;
    position: relative;
    height: 3.25rem;
}
.footer-text {
    font-size: 0.5rem;
    width: auto;
    position: relative;
    line-height: 1.5rem;
    display: inline-block;
    flex-shrink: 0;
}
.social-icon {
    width: 1.39rem;
    position: relative;
    height: 1.39rem;
    overflow: hidden;
    flex-shrink: 0;
}
.social-icons {
    width: 7.69rem;
    height: 2.44rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-left: -0.75rem;
}
.footer-text-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 0.56rem;
}
.frame-parent3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.81rem;
}
.footer {
    align-self: stretch;
    background-color: #cc785c;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.94rem 1rem;
    font-size: 1.5rem;
    color: #fff;
}
.terms-privacy-links {
    /* Align with the existing footer text styles */
    font-size: relative;
    color: #fff;
    display: flex; /* Assuming white color for links */

}

.terms-privacy-links a {
    color: inherit; /* Inherit color from parent */
    text-decoration: none; /* No underline by default */
    margin: 0 5px; /* Spacing around each link */
}

.terms-privacy-links a:hover {
    text-decoration: underline; /* Underline on hover for better UX */
}
.landing-page-mobile {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 2.25rem;
    color: #29261b;
    font-family: Inter;
}

