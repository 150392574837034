/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    max-width: 700px; /* Adjusted for better readability */
    width: 90%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    font-family: Arial, sans-serif; /* Ensure consistent font */
    line-height: 1.6; /* Improve readability */
  }
  
  .modal-close-button {
    background: none;
    border: none;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #333;
  }
  