/* TermsOfUse.css */
.terms-of-use-container {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #333;
  }
  
  h3 {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 12px;
    color: #444;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 12px;
    color: #555;
  }
  
  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  ul li {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
  }
  
  a {
    color: #1155cc;
    text-decoration: underline;
  }
  
  a:hover {
    text-decoration: none;
  }
  